import * as React from "react";
// import * as ReactDOM from "react-dom";
import {
    createBrowserRouter,
    RouterProvider,
    useRouteError,
    isRouteErrorResponse,
    LoaderFunctionArgs
} from "react-router-dom";

import UnlocalizedRoot from './UnlocalizedRoot';
import LocalizedRoot from './LocalizedRoot';
import RootBoundary from './RootBoundary';
import { unlocalizedRootLoader,localizedRootLoader, homepageLoader, landingPageLoader } from 'pages';
import { LandingPage, Home } from "pages";

export default function Root() {
    return <RouterProvider router={router} />;
}

const router = createBrowserRouter([
    {
        path: "/:locale/",
        element: <LocalizedRoot />,
        loader: localizedRootLoader,
        children: [
            {
                path: "",
                element: <Home />,
                loader: homepageLoader,
            },
            {
                path: "*",
                element: <LandingPage />,
                loader: landingPageLoader
                // loader: teamLoader,
            }
        ],
    },
    {
        path: "",
        element: <UnlocalizedRoot />,
        // action:actionLoader
        loader: unlocalizedRootLoader,
        // loader: rootLoader,
        // loader={async ({ params }) => {
        //     const res = await fetch(`/api/properties/${params.id}`);
        //     if (res.status === 404) {
        //       throw new Response("Not Found", { status: 404 });
        //     }
        //     const home = await res.json();
        //     const descriptionHtml = parseMarkdown(
        //       data.descriptionMarkdown
        //     );
        //     return { home, descriptionHtml };
        //   }}
        errorElement: <RootBoundary />
    },
], {
});
