import _ from "lodash";

import { buildImageRenderUrl } from 'utils';

import { DeviceType } from "config";
import type { Image as ImageType } from "types/components";


const Image: React.FC<ImageType> = (props: ImageType) => {
  
  const { image, render } = props
  
  if(!image){
    return null;
  }

  const {  image: { $, title, url } } = props

  return <picture className={render.className}
    // @ts-expect-error
    {...$?.title}>
    {<source media="(max-width: 639px)" srcSet={buildImageRenderUrl(image, render, DeviceType.MOBILE).toString()} />}
    <source media="(min-width: 640px) and (max-width: 1023px)" srcSet={buildImageRenderUrl(image, render,  DeviceType.TABLET).toString()} />
    <img src={buildImageRenderUrl(image, render,  DeviceType.DESKTOP).toString()} alt={title} />
  </picture>
}

export { Image }