import { useEffect, useState, } from 'react'
import { useParams, useLoaderData } from "react-router-dom";

import { inIframe } from 'utils';
import { SingleCol } from 'layout';
import { useContentstack } from 'context'
import { getLandingPage } from "./landingPageLoaders";
import RenderComponents from './RenderComponents';

import { LandingPage as LandingPageType } from 'types/pages';

export default function LandingPage() {
  let routerParams = useParams();
  const preLoadedEntry = useLoaderData() as LandingPageType;
  const cs = useContentstack()
  const [entry, setEntry] = useState<any>({})

  useEffect(() => {
    setEntry(preLoadedEntry);
  }, [preLoadedEntry])

  useEffect(() => {
    async function fetch() {
      const res = await getLandingPage(routerParams);
      setEntry(res);
    }
    if (inIframe()) fetch();
  }, [cs.lpTs])

  return (<SingleCol>
    {entry && Object.keys(entry).length ? (
      <RenderComponents
        pageComponents={entry?.content_blocks}
      />
    ) : <span></span>}
  </SingleCol>
  )
}
