import React, { useEffect } from "react";
// import * as ReactDOM from "react-dom";
import {
    useLocation,
    Outlet,
    useParams,
    useSearchParams,
    Navigate
} from "react-router-dom";
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl'
import ISO6391 from 'iso-639-1';
import ReleasePreview from "@contentstack/delivery-plugin-release-preview";

import { useLocalization } from "hooks";
import { stackSdk} from 'config/contentstack'
import { getReleasePreviewSession } from "utils";

export default function LocalizedRoot() {
    const { defaultLocaleName } = useLocalization();
    const cache = createIntlCache()
    let { locale } = useParams();
    let location = useLocation();
    //   console.log(location.pathname, 'locale', locale, 'slug', sd['*'])
    // console.log(navigator.language, location)
    // const [searchParams] = useSearchParams();
    // useEffect(() => {
    //        (async () => {
    //              const release_preview_options = getReleasePreviewSession(searchParams);
    //         /* stack here is the created Contentstack Stack Instance */
    //                 await ReleasePreview.init(stackSdk, release_preview_options);
    //        })()
    //        console.log(searchParams.get("release_preview_id"))
    //  }, []);


    if (locale) {
        try {
            const tempLocale = new Intl.Locale(locale);
            if (!ISO6391.validate(tempLocale.language) && !tempLocale.region) {
                console.log('No locale or region found:');
                return <Navigate replace to={`/${navigator.language.toLocaleLowerCase()}${location.pathname}`} />
            }
            //   console.log("tempLocale",tempLocale)
            if (ISO6391.validate(tempLocale.language) && tempLocale.region) {
                try {
                    const intl = createIntl({
                        defaultLocale: defaultLocaleName,
                        locale: locale,
                        messages: {}
                    }, cache)
                    return (
                        <RawIntlProvider value={intl}>
                            <Outlet />
                        </RawIntlProvider>
                    );
                } catch (err: any) {
                    console.log("Error parsing locale, falling back to default ", defaultLocaleName)
                    return <Navigate replace to={`/${defaultLocaleName}${location.pathname}`} />
                }
            }
        } catch (err: any) {
            console.log("Error parsing locale, falling back to default ", defaultLocaleName)
            // console.log('Redirecting Intl.Locale() is invalid:', err);
            return <Navigate replace to={`/${defaultLocaleName}${location.pathname}`} />
        }
    }
    // console.debug('Redirecting useParams():', locale);
    return <Navigate replace to={`/${location.pathname}`} />
}