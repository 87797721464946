import { HomepageRenderProps } from 'types/pages';
import { CardCollection, Teaser } from 'components';

export default function RenderComponents(props: HomepageRenderProps) {
  const { pageComponents } = props;
  return (<>
    {pageComponents?.map((component, key: number) => {
      if (component.teaser) {
        return <Teaser key={`component-${key}`} {...component.teaser} />
      }
      if (component.card_collection) {
        return <CardCollection key={`component-${key}`} {...component.card_collection} />
      }
    })}</>)
}
