import React, { createContext, useState } from 'react';

import { ChildrenProps, MyErrorContextType } from 'types/generic';

const ErrorContext = createContext<MyErrorContextType>({} as MyErrorContextType)

function MyErrorContextProvider(props: ChildrenProps) {
  const [error, setError] = useState<string | null>(null)

  const updateError = (value: string) => {
    setError(value)
  }

  return (
    <ErrorContext.Provider
      value={{
        error,
        updateError
      }}
    >
      {props.children}
    </ErrorContext.Provider>
  );
};

const useMyErrorCtx = () => React.useContext(ErrorContext)

export { MyErrorContextProvider, useMyErrorCtx };


