import _ from "lodash";
import { CtaCollection, Image } from "components";

import { TeaserText } from "./TeaserText";

import { ImageRenderSizes } from "config";
import { ImagePosition } from "types/enums";
import type { Teaser as TeaserProps } from "types/components";


const TeaserWithImageVertical: React.FC<TeaserProps> = (props: TeaserProps) => {
  const { $, image, styles, removeMargin, ctas } = props;

  return (
    <div className="bg-white py-6 sm:pt-16">
      {image &&
      image.length > 0 &&
      image[0].image_position === ImagePosition.center_under ? (
        <div className="pb-4">
          <TeaserText {...props} />
        </div>
      ) : (
        ""
      )}

      <div className="mx-auto max-w-7xl px-4 sm:pb-8 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden rounded-lg">
          <div className="relative py-32 px-6 sm:py-40 sm:px-12 lg:px-16 ">
            <div className="absolute bg-cover inset-0">
              {image && image.length > 0 ? (
                <Image
                  image={image[0].image}
                  render={{
                    className: "h-full w-full object-center",
                    ariaHidden: "true",
                    desktop: {
                      width: ImageRenderSizes.ContainerFullWidth,
                      crop: "3:2,smart",
                    },
                    tablet: {
                      width: ImageRenderSizes.ContainerFullWidth,
                      crop: "3:2,smart",
                    },
                    mobile: {
                      width: ImageRenderSizes.ContainerMobileFullWidth,
                      crop: "4:3,smart",
                    },
                  }}
                />
              ) : (
                ""
              )}
            </div>
            {/* <TeaserText {...props} /> */}
          </div>
        </div>
      </div>

      {image &&
      image.length > 0 &&
      image[0].image_position === ImagePosition.center_over ? (
        <TeaserText {...props} />
      ) : (
        ""
      )}
      {/* <CtaCollection ctas={ctas} arrangement={styles?.cta_arrangement} /> */}
    </div>
  );
};
export { TeaserWithImageVertical };
