import _ from "lodash";

import { getEntry } from './sdkWrapper';
import { getWebConfig, setWebConfig } from 'utils'
import { defaultLocaleName } from "config";

import { Locale, WebConfiguration } from 'types/generic';

const webConfigurationUID = 'web_configuration';

export const getWebConfiguration = () => {
    const webConfig = getWebConfig();

    if (webConfig && webConfig.expires && new Date(webConfig.expires) > new Date()) {
            return webConfig;
    } else {
        return getServerWebConfiguration()
    }
}

export const getServerWebConfiguration = async () => {
    const webConfig = await getEntry(defaultLocaleName, webConfigurationUID, null, null) as WebConfiguration;
    setWebConfig(webConfig);
    return webConfig;
}

export const getLocaleConfig = (localeName: string): Locale | undefined => {
    const webConfig = getWebConfig();

    if (webConfig) {
        const val = _.chain(webConfig.markets)
            .map('locales')               // pluck all elements from data
            .flatten()                     // flatten the elements into a single array
            .find({ locale_name: localeName })
            .value()

        return val
    }
    return undefined;
}