import _ from "lodash";

import { CtaCollection } from "components";
import JsonRte from "components/Primitives/JsonRte";
import { TeaserImage } from "./TeaserImage";
import { TeaserText } from "./TeaserText";
import { Image } from "components";
import { ImageRenderSizes } from "config";

import { ImagePosition } from "types/enums";
import type { Teaser as TeaserProps } from "types/components";

const TeaserWithImageHorizontal: React.FC<TeaserProps> = (
  props: TeaserProps
) => {
  const { $, title, subtitle, text, ctas, image, styles, removeMargin } = props;

  // Image Right
  return (
    <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-24 lg:px-4">
      <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
        <div className="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
          {image &&
          image.length > 0 &&
          image[0].image_position === ImagePosition.right ? (
            <div className="py-4 ">
              <TeaserText {...props} />
            </div>
          ) : (
            ""
          )}

          <div className="aspect-h-2 lg:aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100">
            {image && image.length > 0 ? (
              <Image
                image={image[0].image}
                render={{
                  className: "object-cover object-center !absolute",
                  ariaHidden: "true",
                  desktop: {
                    width: ImageRenderSizes.ContainerFullWidth,
                    crop: "4:6,smart",
                  },
                  tablet: {
                    width: ImageRenderSizes.ContainerTabletFullWidth,
                    crop: "4:4,smart",
                  },
                  mobile: {
                    width: ImageRenderSizes.ContainerMobileFullWidth,
                    crop: "4:4,smart",
                  },
                }}
              />
            ) : (
              ""
            )}
          </div>

          {image &&
          image.length > 0 &&
          image[0].image_position === ImagePosition.left ? (
            <div className="py-4 text-justify">
              <TeaserText {...props} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
export { TeaserWithImageHorizontal };
