import { FunctionComponent } from "react";
import parse from "html-react-parser";

import { JsonRte as JsonRteProps } from "types/components";

const JsonRte: FunctionComponent<JsonRteProps> = (props: JsonRteProps) => {
  const { body } = props;

  if (typeof body === "object") {
    console.error(
      "JSON Object found, object needs to be parsed to string -> jsonToHTML()"
    );
    return <></>;
  }

  return <>{body && parse(body)}</>;
};

export default JsonRte;