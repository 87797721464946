import { LoaderFunctionArgs, Params } from "react-router-dom";

import { imageCardsPaths, teaserPaths } from 'components';
import {  prefixReferenceIncludes } from 'utils';
import { getEntryByUrl, getLocaleConfig } from 'services';

const newRefUids =
    prefixReferenceIncludes('content_blocks',
        ...prefixReferenceIncludes('card_collection', ...imageCardsPaths.referenceIncludes),
        ...prefixReferenceIncludes('teaser', ...teaserPaths.referenceIncludes),
        // ...prefixReferenceIncludes('text_cta', ...textCtaReferenceIncludes)
    );

const jsonRtePaths = prefixReferenceIncludes('content_blocks',
    ...prefixReferenceIncludes('card_collection', ...imageCardsPaths.jsonRTEs),
    ...prefixReferenceIncludes('teaser', ...teaserPaths.jsonRTEs),
);

export const landingPageLoader = (args: LoaderFunctionArgs) => {
    const { params } = args
    return getLandingPage(params)
}

export const getLandingPage = (params: Params) => {
    return getLandingpageEntry(params["locale"], params["*"])
}

export const getLandingpageEntry = (locale: string | undefined, cmsUrlPath: string | undefined) => {
    if (locale && cmsUrlPath) {
        const localeConfig = getLocaleConfig(locale);
        return getEntryByUrl(locale, 'landing_page', `/${cmsUrlPath}`, newRefUids, jsonRtePaths)
    } else {
        console.log("Error fetching langind page entry, not locale/URLpath found")
    }
}