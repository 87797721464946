import { LoaderFunctionArgs, Params } from "react-router-dom";

import { setLocaleCookie } from "utils";

export const localizedRootLoader = (args: LoaderFunctionArgs) => {
    const { params } = args
    const locale = params["locale"]

    // redirects to locale site if there is a locale cookie already defined
    if (locale) {
        setLocaleCookie(locale)
    }
}
