import React, { createContext, useEffect, useState } from 'react';
import ContentstackLivePreview from '@contentstack/live-preview-utils';

import { ChildrenProps, ContentstackContextType } from 'types/generic';
import { isLivePreviewEnabled } from 'config';

const ContentstackContext = createContext<ContentstackContextType>({} as ContentstackContextType)

function ContentstackProvider(props: ChildrenProps) {
  const [lpTs, setLpTs] = useState(0);
  let initialized = false;

  useEffect(() => {

    if (isLivePreviewEnabled && !initialized) {
      initialized = true
      ContentstackLivePreview.onLiveEdit(updateContent);
    }
  }, []);

  const updateContent = () => {
    setLpTs(Date.now());
  };

  return (
    <ContentstackContext.Provider
      value={{
        lpTs
      }}
    >
      {props.children}
    </ContentstackContext.Provider>
  );
};

const useContentstack = () => React.useContext(ContentstackContext)

export { ContentstackProvider, useContentstack };

