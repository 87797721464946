import {useLoaderData} from "react-router-dom";

import { WebConfiguration } from 'types/generic';
import { HeroLanguageSelector } from 'components'

export default function UnlocalizedRoot() {
  const entry = useLoaderData() as WebConfiguration;

  return <HeroLanguageSelector markets={entry?.markets} backgroundImage={entry?.language_selector?.background_image}/>
}
