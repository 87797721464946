import { classNames } from "utils";
import _ from "lodash";
import { CtaCollection, Image } from "components";
import JsonRte from "components/Primitives/JsonRte";
import { TeaserImage } from "./TeaserImage";
import { TeaserText } from "./TeaserText";

import { ImagePosition } from "types/enums";
import type { Teaser as TeaserProps} from "types/components";
import { ImageRenderSizes } from "config";

const TeaserWithImageBackground: React.FC<TeaserProps> = (props: TeaserProps) => {
  const { $, image, styles, removeMargin, ctas } = props;

  return (
    <div className="bg-white py-6 sm:pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:pb-8 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden rounded-lg">
          <div className="relative py-2 px-6 sm:py-8 sm:pt-24 sm:px-12 lg:px-16 ">
            <div className="absolute bg-cover inset-0">
              {image && image.length > 0 ? (
                <Image
                  image={image[0].image}
                  render={{
                    className: "h-full w-full object-center",
                    ariaHidden: "true",
                    desktop:{
                    auto:true,
                    width:ImageRenderSizes.ContainerFullWidth
                  }}}
                />
              ) : (
                ""
              )}
            </div>
            <TeaserText {...props} />
          </div>
        </div>
      </div>

      {/* <CtaCollection ctas={ctas} alignment={styles?.cta_alignment} /> */}
    </div>
  );
};
export { TeaserWithImageBackground };
