import { CtaButton } from './CtaButton';
import { CtaLink } from './CtaLink';

import { CtaType } from 'types/enums';
import type { Cta as CtaProps, } from 'types/components';


const Cta: React.FC<CtaProps> = (props: CtaProps) => {
  const { type } = props

  return (<div className='text-sm font-semibold text-black no-underline'>
      {type === CtaType.button ? 
      <CtaButton {...props} /> : 
      <CtaLink {...props} />}
  </div>
  )
}
export { Cta }

