import _ from "lodash";
import { LoaderFunctionArgs, Params } from "react-router-dom";
import ReleasePreview from "@contentstack/delivery-plugin-release-preview";

import { prefixReferenceIncludes, getReleasePreviewSession } from "utils";
import { imageCardsPaths, teaserPaths } from "components";
import { stackSdk } from "config/contentstack";
import { getEntryByUrl } from "services";

const newRefUids = prefixReferenceIncludes(
  "content_blocks",
  ...prefixReferenceIncludes(
    "card_collection",
    ...imageCardsPaths.referenceIncludes
  ),
  ...prefixReferenceIncludes("teaser", ...teaserPaths.referenceIncludes)
  // ...prefixReferenceIncludes('text_cta', ...textCtaReferenceIncludes)
);

const jsonRtePaths = prefixReferenceIncludes(
  "content_blocks",
  ...prefixReferenceIncludes("card_collection", ...imageCardsPaths.jsonRTEs),
  ...prefixReferenceIncludes("teaser", ...teaserPaths.jsonRTEs)
);

export const homepageLoader = (args: LoaderFunctionArgs) => {
  const {
    params,
    request: { url },
  } = args;
  const newUrl = new URL(url);

  //       console.log(searchParams.get("release_preview_id"), searchParams.toString())
  //  console.log(document.location)
  // console.log(args.request.url,searchParams)
  // console.log(newUrl)
  return getHomepage(params, newUrl.searchParams);
};

export const getHomepage = async (params: Params, searchParams: URLSearchParams) => {
  await initReleasePreview(searchParams)
  
  return getHomepageEntry(params["locale"], params["*"]);
};

const initReleasePreview = (searchParams: URLSearchParams) => {
  // const s = JSON.stringify( stackSdk)
  // console.log("stackSdk", JSON.parse(s))
  const release_preview_options = getReleasePreviewSession(searchParams);
  // console.log("stackSdk", stackSdk)
  return ReleasePreview.init(stackSdk, release_preview_options);
};

export const getHomepageEntry = (
  locale: string | undefined,
  cmsUrlPath: string | undefined
) => {
  if (locale) {
    if (_.isEmpty(cmsUrlPath)) {
      return getEntryByUrl(locale, "home_page", `/`, newRefUids, jsonRtePaths);
    } else {
      return getEntryByUrl(
        locale,
        "home_page",
        `/${cmsUrlPath}`,
        newRefUids,
        jsonRtePaths
      );
    }
  } else {
    console.log("Error fetching homepage entry, not locale found");
  }
};
