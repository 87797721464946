import { Stack } from "contentstack";
import ContentstackLivePreview from "@contentstack/live-preview-utils";
import { releaseReplaceAlgorithm, releaseReplacePreReq } from "@contentstack/delivery-plugin-release-preview";

import {ContentstackMiddlewarePlugin} from "services/contentstack";

export const isLivePreviewEnabled = process.env.REACT_APP_CONTENTSTACK_LIVE_PREVIEW === 'true';
export const isReleasePreviewEnabled = process.env.REACT_APP_RELEASE_PREVIEW === 'true';
export const isEditButtonsEnabled = process.env.REACT_APP_CONTENTSTACK_LIVE_EDIT_TAGS === 'true';
export const extensions = {
  assetPresetUid: process.env.REACT_APP_CONTENTSTACK_EXTENSION_ASSET_PRESET ? process.env.REACT_APP_CONTENTSTACK_EXTENSION_ASSET_PRESET : ''
}

class ReleasePreviewPlugin {
  onRequest (stack, request) {
    releaseReplacePreReq(stack, request);
    return request;
  }
  async onResponse(stack, request, response, data) {
    console.log(data)
    const _data = data['entries'] || data['assets'] || data['entry'] || data['asset'];
    await releaseReplaceAlgorithm(_data, stack);
    return data
  }
 }

const sdkConfig = {
  api_key: process.env.REACT_APP_CONTENTSTACK_API_KEY,
  delivery_token: process.env.REACT_APP_CONTENTSTACK_DELIVERY_TOKEN,
  environment: process.env.REACT_APP_CONTENTSTACK_ENVIRONMENT,
  // region: process.env.REACT_APP_CONTENTSTACK_REGION ? process.env.REACT_APP_CONTENTSTACK_REGION : 'us',
  live_preview: {
    management_token: process.env.REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN ? process.env.REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN : '',
    enable: true,
    host: process.env.REACT_APP_CONTENTSTACK_API_HOST ? process.env.REACT_APP_CONTENTSTACK_API_HOST : '',
  },
  plugins:[]
}

if(process.env.REACT_APP_MIDDLEWARE_HOST){
  sdkConfig.plugins.push(new ContentstackMiddlewarePlugin())
}

if(isReleasePreviewEnabled){
  sdkConfig.plugins.push(new ReleasePreviewPlugin())
}

export const stackSdk = Stack(sdkConfig)

/**
 * initialize live preview
 */
ContentstackLivePreview.init({
  enable: true,
  stackSdk,
  clientUrlParams: {
    host: `${process.env.REACT_APP_CONTENTSTACK_APP_HOST}`
      ? `${process.env.REACT_APP_CONTENTSTACK_APP_HOST}`
      : "",
  },
  cleanCslpOnProduction:false,
  editButton: {
    enable: true,
    // exclude: ["outsideLivePreviewPortal"],
    includeByQueryParameter: false,
    position:'top-right',
  },
  ssr: false,
});

// if (`${process.env.REACT_APP_CONTENTSTACK_API_HOST}`) {
//   stackSdk.setHost(`${process.env.REACT_APP_CONTENTSTACK_API_HOST}`);
// }

