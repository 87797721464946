import { IntlProvider } from "react-intl";

import { MyErrorContextProvider, ContentstackProvider } from 'context';
import { useLocalization } from "hooks";

import Root from './router/Router';
function App() {
  const { currentlocaleName, defaultLocaleName } = useLocalization();

  return (
    <IntlProvider locale={currentlocaleName} defaultLocale={defaultLocaleName}>
        <ContentstackProvider>
          <MyErrorContextProvider>
            <Root />
          </MyErrorContextProvider>
        </ContentstackProvider>
    </IntlProvider>
  );
}

export default App;
