import { LandingPageRenderProps } from 'types/pages';
import { CardCollection, Teaser } from 'components';

export default function RenderComponents(props: LandingPageRenderProps) {
  const { pageComponents } = props;

  return (<>
    {pageComponents?.map((component, key: number) => {
      if (component.teaser) {
        return <Teaser key={`teaser-${key}`} {...component.teaser} />
      }
      if (component.card_collection) {
        return <CardCollection key={`cardCollection-${key}`} {...component.card_collection} />
      }
    })}</>)
}