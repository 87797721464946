import { addEditableTags, jsonToHTML } from '@contentstack/utils';

import { renderOptions } from 'utils';
import { stackSdk, isEditButtonsEnabled } from 'config/contentstack'
import { getLocaleConfig } from './webConfiguration';

/**
 *
 * fetches single entry from specific content-type
 * @param {* content-type uid} contentTypeUid
 * @param {* reference field name} referenceFieldPath
 * @param {* Json RTE path} jsonRtePath
 *
 */
export const getEntry = async (locale, contentTypeUid, referenceFieldPath, jsonRtePath) => {
  const entries = await getEntries(locale, contentTypeUid, referenceFieldPath, jsonRtePath);

  if (entries.length === 1) {
    return entries[0][0];
  } else {
    if (entries.length > 1) {
      console.warn(`getEntry function returned first element of array for type ${contentTypeUid}, only 1 item was expected!!!`);
      return entries[0][0]
    } else {
      return [];
    }
  }
}

/**
 *
 * fetches all the entries from specific content-type
 * @param {* content-type uid} contentTypeUid
 * @param {* reference field name} referenceFieldPath
 * @param {* Json RTE path} jsonRtePath
 *
 */
export const getEntries = (locale, contentTypeUid, referenceFieldPath, jsonRtePath) => {
  return new Promise((resolve, reject) => {
    const entryQuery = stackSdk.ContentType(contentTypeUid).Query();

    if (referenceFieldPath) {
      entryQuery.includeReference(referenceFieldPath);
    }

    entryQuery
      .language(locale)
      // .includeOwner()
      .addParam('include_dimension', 'true')
      .toJSON()
      .find()
      .then(
        (result) => {
          if (jsonRtePath) {
            jsonToHTML({
              entry: result,
              paths: jsonRtePath,
              renderOption: renderOptions
            });
          }
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
  });
}

/**
 *fetches specific entry from a content-type
 *
 * @param {* content-type uid} contentTypeUid
 * @param {* url for entry to be fetched} entryUrl
 * @param {* reference field name} referenceFieldPath
 * @param {* Json RTE path} jsonRtePath
 * @returns
 */
export const getEntryByUrl = async (locale, contentTypeUid, entryUrl, referenceFieldPath, jsonRtePath) => {
  const localeConfig = getLocaleConfig(locale);

  const entryQuery = stackSdk.ContentType(contentTypeUid).Query()
  if (referenceFieldPath) entryQuery.includeReference(referenceFieldPath);

  if (localeConfig.allow_fallback) {
    entryQuery
      .includeFallback()
  }

  entryQuery
    .language(locale)
    // .includeOwner()
    .includeEmbeddedItems()
    .addParam('include_metadata', 'true')
    .addParam('include_dimension', 'true')
    .toJSON();

  const result = await entryQuery.where('url', `${entryUrl}`).find();
  if (jsonRtePath) {
    jsonToHTML({
      entry: result,
      paths: jsonRtePath,
      renderOption: renderOptions,
    });
  }

  isEditButtonsEnabled && addEditableTags(result[0][0], contentTypeUid, true, locale);

  const data = result[0][0]
  return data;

}