import _ from "lodash";
import { getMockTitle, getMockShortParagraphs, getSampleImage } from 'utils';

import type { ImageCardItem } from 'types/components';
import {  TextPosition } from 'types/enums';

export const getCardDefaultValues = () => {
  return {
    image: {
      title: getMockTitle(),
      url: getSampleImage()
    },
    title: getMockTitle(),
    description: getMockShortParagraphs(1),
    link: {
      internal_link: [{
        url: '#',
        title: getMockTitle(),
        locale:''
      }],
      url: '#'
    },
    textPosition: TextPosition.below,
  }
}

export const defaultValues: ImageCardItem = getCardDefaultValues();

