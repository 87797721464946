import _ from "lodash";
import { classNames } from "utils";

import { CtaCollection, Image } from "components";
import JsonRte from "components/Primitives/JsonRte";
import { TeaserImage } from "./TeaserImage";
import { TeaserText } from "./TeaserText";

import { ImagePosition, BackgroundColor } from "types/enums";
import type { Teaser as TeaserProps } from "types/components";

const TeaserWithoutImage: React.FC<TeaserProps> = (props: TeaserProps) => {
  const { $, image, styles, removeMargin } = props;

  return (
    <div className="bg-white py-6 sm:pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:pb-8 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden rounded-lg">
          <div className="relative py-12 px-6 sm:py-20 sm:px-12 lg:px-16 ">
            <div
              className={classNames(
                styles && styles?.background_color === BackgroundColor.primary
                  ? "bg-primary-background"
                  : styles?.background_color === BackgroundColor.secondary
                  ? "bg-secondary-background"
                  : styles?.background_color === BackgroundColor.tertiary
                  ? "bg-tertiary-background"
                  : "",
                "absolute bg-cover inset-0"
              )}
            ></div>
            <TeaserText {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};
export { TeaserWithoutImage };
