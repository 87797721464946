import _ from "lodash";
import { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import clm from 'country-locale-map';

import { Combobox } from 'components';
import { isoLangs } from 'data/isoLangs';
import { buildImageRenderUrl, capitalizeFirstLetter, getPreferedBrowserRegions, setLocaleCookie } from "utils";

import { Locale, Market } from "types/generic";
import { HeroLanguageSelector as HeroLanguageSelectorProps, CbOption } from 'types/components'


const HeroLanguageSelector: FunctionComponent<HeroLanguageSelectorProps> = (props: HeroLanguageSelectorProps) => {
  const navigate = useNavigate();
  const { markets, backgroundImage } = props

  const regionOptions: CbOption[] = markets ? markets?.map((el: Market) => {
    return {
      value: el.code,
      text: el.description,
      emojiUnicode: clm.getCountryByAlpha2(el.code.toUpperCase())?.emoji
    }
  }
  ) : [];

  const preferedAvailableRegion = getPreferedAvailableRegion(regionOptions);
  const preferedRegionOption = preferedAvailableRegion ? preferedAvailableRegion[0] : regionOptions[0]
  const [defaultRegion, setDefaultRegion] = useState<CbOption | null>(preferedRegionOption);
  const [localeOptions, setLocaleOptions] = useState<CbOption[]>([])
  const preferedLocaleOption = getPreferedBrowserLocale(findMarket(markets, preferedRegionOption));
  const [defaultLocale, setDefaultLocale] = useState<CbOption | null>(preferedLocaleOption)
  const [selectedLocale, setSelectedLocale] = useState<CbOption | null>(preferedLocaleOption)

  const onRegionChange = (item: CbOption | null) => {
    const mkt = findMarket(markets, item)
    if (mkt) {

      const options = mkt.locales?.map((el: Locale) => {
        return {
          value: el.locale_name,
          text: getIsoLangName(el.locale_name),
        }
      })
      setLocaleOptions(options);
      const prefBrowserLocale = getPreferedBrowserLocale(mkt);
      setDefaultLocale(prefBrowserLocale);
      setSelectedLocale(prefBrowserLocale);
    }
  }

  const onLocaleChange = (cbLocale: CbOption) => {
    setSelectedLocale(cbLocale);
  }

  const onGoClick = () => {
    if (selectedLocale) {
      // sets a locale cookie
      setLocaleCookie(selectedLocale.value)

      navigate(`/${selectedLocale.value}`)
    }
  }


  return (<div className='relative'>
    <div className="flex flex-col h-screen">
      {backgroundImage && <div className="absolute h-screen w-screen bg-cover bg-center "
        style={{ backgroundImage: `url(${buildImageRenderUrl(backgroundImage, { className: '', ariaHidden: 'true', desktop:{ width: 2500}}).toString()})` }}>
      </div>}
      <div className="relative h-screen w-screen sm:px-6 py-96 lg:px-24 bg-gray-900 bg-opacity-50">
        <div className='mx-auto mt-24'>
          <div className='sm:flex'>
            <div className='flex-initial sm:w-64 mx-4 my-4'>

              <Combobox
                items={regionOptions}
                onValueChange={onRegionChange}
                defaultItem={defaultRegion}
              />
            </div>
            <div className='flex-initial sm:w-64 mx-4 my-4'>
              <Combobox
                items={localeOptions}
                onValueChange={onLocaleChange}
                defaultItem={defaultLocale}
              />
            </div>
            <div className='mx-4 my-4'>
              <a
                onClick={() => onGoClick()}
                className="block px-2 py-2 rounded-md text-center border border-gray-500 bg-black text-base font-medium text-white 
               sm:my-0 sm:max-h-full sm:h-10 sm:w-10 
               hover:bg-opacity-90 hover:bg-gray-800"
              >
                GO
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default HeroLanguageSelector

const getPreferedAvailableRegion = (countries: CbOption[]) => {
  const browserRegions = getPreferedBrowserRegions();
  const av = _.reduce(browserRegions, function (regions: CbOption[], br: string) {
    const country = _.find(countries, { 'value': br });
    if (country) {
      regions.push(country);
    }
    return regions;
  }, []);
  return av;
}

const getPreferedBrowserLocale = (market: Market | null | undefined) => {
  if (market) {
    const av = _.reduce(navigator.languages, function (locales: CbOption[], l: string) {
      const LocaleLowerCased = l.toLocaleLowerCase();
      const locale = _.find(market.locales, { 'locale_name': LocaleLowerCased });
      if (locale) {
        const loc: CbOption = {
          value: LocaleLowerCased,
          text: getIsoLangName(locale.locale_name)
        }
        locales.push(loc);
      }
      return locales;
    }, []);
    //if it found the exact match in the prefered browser locales
    if (av && av.length) {
      return av[0];
    } else {
      //tries to find the second best match in the prefered browser locales
      const langs = _.filter(navigator.languages, (l: string) => l.length === 2)
      const av = _.reduce(langs, function (locales: CbOption[], l: string) {
        const langLowerCased = l.toLocaleLowerCase();
        const locale = _.find(market.locales, (locale: Locale) => {
          return _.isEqual(locale.locale_name.substring(0, 2), langLowerCased);
        });
        if (locale) {
          const loc: CbOption = {
            value: locale.locale_name,
            text: getIsoLangName(locale.locale_name)
          }
          locales.push(loc);
        }
        return locales;
      }, []);
      //if it found the second best match in the prefered browser locales
      if (av && av.length) {
        return av[0];
      } else {
        // gets the first locale in the list
        if (market.locales && market.locales.length) {
          const locale = market.locales[0];
          return {
            value: locale.locale_name,
            text: getIsoLangName(locale.locale_name)
          }
        }
      }
    }
  }
  return null;
}

const findMarket = (markets: Market[], item: CbOption | null) => {
  let market: Market | null | undefined = null;
  if (item) {
    market = markets.find((mk: Market) => {
      return mk.code === item.value
    })

  }
  return market;
}

const getIsoLangName = (localeName: string) => {
  const langCountry = localeName.split('-')
  return capitalizeFirstLetter(isoLangs[langCountry[0]].nativeName)
}