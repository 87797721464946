import { useEffect, useState } from 'react'
import { useParams, useSearchParams, useLoaderData } from 'react-router-dom'

import { inIframe } from 'utils';
import { SingleCol } from 'layout';
import { useContentstack } from 'context'
import { getHomepage } from "./homeLoaders";
import RenderComponents from './RenderComponents';

import { Homepage } from 'types/pages/homepage'

export default function Home() {
  let routerParams = useParams();
  const [searchParams] = useSearchParams();
  const preLoadedEntry = useLoaderData() as Homepage;
  const cs = useContentstack()
  const [entry, setEntry] = useState<Homepage | undefined>()

  useEffect(() => {
    setEntry(preLoadedEntry);
  }, [preLoadedEntry])



  useEffect(() => {
    async function fetch() {
      const res = await getHomepage(routerParams,searchParams);
      setEntry(res);
    }
    if (inIframe()) fetch();
  }, [cs.lpTs, routerParams, searchParams])

  return (<SingleCol>
    {entry && Object.keys(entry).length ? (
      <RenderComponents
        pageComponents={entry?.content_blocks}
      />
    ) : <span></span>}
  </SingleCol>
  )

}
