import { classNames } from "utils";
import _ from "lodash";
import { CtaCollection } from "components";
import JsonRte from "components/Primitives/JsonRte";
import { TeaserText as TeaserTextProps } from "types/components";

const TeaserText: React.FC<TeaserTextProps> = (props: TeaserTextProps) => {
  const { $, title, subtitle, text, ctas, styles } = props;
  // Center Under
  return (
    <>
      {/* <div aria-hidden="true" className="absolute w-full pl-24 pt-32 bg-gray-600 opacity-50" /> */}
      <div
        className={classNames(
          !styles || !styles.text_alignment || styles.text_alignment === "left"
            ? "items-start"
            : styles.text_alignment === "center"
            ? "items-center"
            : styles.text_alignment === "right"
            ? "items-end"
            : "",
          "relative flex flex-col max-w-7xl px-4 sm:px-6 lg:px-8 text-gray-800 "
        )}
      >
        <h3
          className="text-lg font-normal text-tertiary-normal tracking-wide sm:text-xl"
          // @ts-expect-error
          {...$?.subtitle}
        >
          {subtitle}
        </h3>
        <h2
          className="text-3xl font-bold tracking-tight  sm:text-4xl"
          // @ts-expect-error
          {...$?.title}
        >
          {title}
        </h2>

        <span
          className="mt-3 mb-3 text-xl"
          // @ts-expect-error
          {...$?.text}
        >
          <JsonRte body={text} />
        </span>
      </div>
      <div className={classNames(
          !styles || !styles.cta_alignment || styles.cta_alignment === "left"
            ? "items-start"
            : styles.cta_alignment === "center"
            ? "items-center"
            : styles.cta_alignment === "right"
            ? "items-end"
            : "",
          "relative flex flex-col max-w-7xl px-4 sm:px-6 lg:px-8 text-gray-800 "
        )}>
        <CtaCollection ctas={ctas} arrangement={styles?.cta_arrangement} />
      </div>
    </>
  );
};
export { TeaserText };
