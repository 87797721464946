import JsonRte from "components/Primitives/JsonRte";
import { TeaserImage } from "./TeaserImage";
import { TeaserWithImageVertical } from "./TeaserWithImageVertical";
import { TeaserWithImageHorizontal } from "./TeaserWithImageHorizontal";
import { TeaserWithImageBackground } from "./TeaserWithImageBackground";
import { TeaserWithoutImage } from "./TeaserWithoutImage";

import { ImagePosition } from "types/enums";
import { Teaser as TeaserProps } from "types/components";

const Teaser: React.FC<TeaserProps> = (props: TeaserProps) => {
  const { $, title, subtitle, text, ctas, image, styles, removeMargin } = props;
  // console.log(image)

  if (image && image.length) {
    if (
      image[0].image_position === ImagePosition.center_over ||
      image[0].image_position === ImagePosition.center_under
    ) {
      return <TeaserWithImageVertical {...props} />;
    } else if (
      image[0].image_position === ImagePosition.background_center ||
      image[0].image_position === ImagePosition.background_stretch
    ) {
      return <TeaserWithImageBackground {...props} />;
    } else {
      return <TeaserWithImageHorizontal {...props} />;
    }
  } else {
    return <TeaserWithoutImage {...props} />;
  }
};
export { Teaser };

// import { CtaCollection } from '../Cta';

// import { classNames } from 'utils';
// import { Teaser as TeaserProps } from 'types/components';

// const Teaser: React.FC<TeaserProps> = (props: TeaserProps) => {
//     const { $,title,subtitle, text, ctas, image, backgroundColor,removeMargin } = props

//     // image?: {
//     //   image : Asset
//     //   image_alignment: ImageAlignment
//     // }

//     return (
//         <div
//             className={classNames(
//                 removeMargin ? '' : 'py-16 px-4 sm:py-20 sm:px-6 lg:px-8',
//                 'max-w-2xl mx-auto text-center'
//             )}>
//             {title ? (
//                 <h2 className='text-2xl lg:text-3xl font-extrabold sm:text-4xl'
//                     // @ts-ignore
//                     {...$?.title}>
//                     <span>{title}</span>
//                 </h2>
//             ) : (
//                 ''
//             )}
//             {text
//                 && (
//                     <div className='py-2 leading-6 text-lg'
//                         // @ts-ignore
//                         {...$?.paragraph}
//                     >
//                         {/* {ReactHtmlParser(contents.paragraph)} */}
//                     </div>
//                 )}
//             {/* <CtaCollection ctas={ctas}/> */}
//         </div>
//     )
// }
// export { Teaser }
