export const getReleasePreviewSession = (searchParams: URLSearchParams) => {
  const release_session_key = "release_preview_session";
  const tentativeReleases = searchParams.get("tentativeReleases");
  const release_id = searchParams.get("release_preview_id");
  const params = {
    enabled: true,
    release_id,
    tentativeReleases:
      tentativeReleases && JSON.parse(decodeURIComponent(tentativeReleases)),
  };
  const releaseSessionInfo = sessionStorage.getItem(release_session_key);
  if (release_id || !releaseSessionInfo) {
    sessionStorage.setItem(release_session_key, JSON.stringify(params));
    return params;
  }
  return JSON.parse(releaseSessionInfo);
};

