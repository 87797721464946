import _ from "lodash";

import { extensions } from "config/contentstack";

import { Asset } from "types/common";
import { ImageRender } from "types/components";
import { AssetMetadata } from "types/extensions";
import { DeviceType } from "config";

export const buildImageRenderUrl = (
  asset: Asset,
  render: ImageRender,
  presetName?: string
) => {
  const assetUrl = new URL(asset.url);
  const preset = getImagePreset(asset, presetName);
  const presetRender = getPresetRender(render, presetName)

  //shortcircuit if no preset is added
  if(_.isEmpty(presetRender)){
    return assetUrl;
  }

  if (presetRender.disableUpscale === undefined || presetRender.disableUpscale) {
    assetUrl.searchParams.append("disable", "upscale");
  }
  //only applies this property to reduce the image size
  if (
    presetRender.width &&
    asset.dimension &&
    asset.dimension!.width > presetRender.width
  ) {
    assetUrl.searchParams.append("width", presetRender.width.toString());
  }

  //Only applyed if there is no preset or there is a preset but no width - to prevent overriding the preset settings but still reducing the total image size
  if (
    presetRender.height &&
    asset.dimension &&
    asset.dimension!.height > presetRender.height &&
    (!preset || presetRender.width)
  ) {
    assetUrl.searchParams.append("height", presetRender.height.toString());
  } else {
    if (presetRender.height) {
      assetUrl.searchParams.append("height", presetRender.height.toString());
    }
    if (presetRender.width) {
      assetUrl.searchParams.append("width", presetRender.width.toString());
    }
  }
  // Don't apply if there is a preset
  if ((presetRender.auto === undefined || presetRender.auto) && !preset) {
    assetUrl.searchParams.append("auto", "webp");
  }
  if (presetRender.crop && !preset) {
    assetUrl.searchParams.append("crop", presetRender.crop);
  }
  if (presetRender.dpr) {
    assetUrl.searchParams.append("dpr", presetRender.dpr.toString());
  }

  //Apply the preset parameters
  if (preset) {
    const params = preset["query-params"].split("&");
    _.forEach(params, (val) => {
      const keyVal = val.split("=");

      //TODO: workaround - check on ticket https://contentstack.atlassian.net/browse/MKT-1557
      if (!_.isEqual(keyVal[0], "width") && !_.isEqual(keyVal[0], "height")) {
        assetUrl.searchParams.append(keyVal[0], keyVal[1]);
      }
    });
  }
  return assetUrl;
};

export const getAssetMetadata = (asset: Asset, extensionId: string) => {
  const { _metadata } = asset;
  if (_.has(_metadata?.extensions, extensionId)) {
    return _metadata?.extensions[extensionId][0] as AssetMetadata;
  }

  return undefined;
};

export const getImagePreset = (asset: Asset, presetName?: string) => {
  if (presetName) {
    const assetMeta = getAssetMetadata(asset, extensions.assetPresetUid);
    // console.log("assetMeta",asset)
    if (assetMeta?.presets) {
      if (assetMeta.presets && assetMeta.presets.length) {
        return _.find(assetMeta.presets, ["name", presetName]);
      }
    }
  }

  return undefined;
};

const getPresetRender = ( render: ImageRender, presetName?: string) =>{
  if(presetName === DeviceType.MOBILE){
    return render.mobile;
  }else if(presetName === DeviceType.TABLET){
    return render.tablet;
  }else {
    return render.desktop;
  }
}