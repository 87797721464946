
// ######################### ENUMS #########################
export enum TextPosition {
    over = 'over',
    below = 'below',
  }
  
  export enum TextAlignment {
    left = 'left',
    center = 'center',
    right = 'right'
  }
  
  export enum ImagePosition {
    left = 'left',
    right = 'right',
    center_over = 'center_over',
    center_under = 'center_under',
    background_center = 'background_center',
    background_stretch = 'background_stretch'
  }
  
  export enum Color {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
  }
  
  export enum BackgroundColor {
    none = 'none',
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
  }
  
  export enum CtaType {
    button = 'button',
    link = 'link'
  }
  
  export enum CtaArrangement {
    horizontal = 'horizontal',
    vertical = 'vertical'
  }
  
  export enum CtaAlignment {
    left = 'left',
    center = 'center',
    right = 'right'
  }
  
  export enum ValidityStatus {
    valid = 'valid',
    invalid = 'invalid'
  }