import * as ReactDOMServer from "react-dom/server";

import { classNames } from "utils";
import { Image } from "components";

import { Asset, Entry } from "types/common";
import { AssetAlignment, AssetMetadata } from "types/jsonRte";

export const renderOptions = {
  // to render block-type embedded items
  block: {
    // product: (entry, metadata) => {
    //   return `<div>
    //     <h2 >${entry.title}</h2>
    //     <img src=${entry.product_image.url} alt=${entry.product_image.title}/>
    //     <p>${entry.price}</p>
    //   </div>`
    // },
    // // to render the default
    // $default: (entry, metadata) => {
    //   return `<div>
    //              <h2>${entry.title}</h2>
    //              <p>${entry.description}</p>
    //          </div>`
    // }
  },
  // to display inline embedded items
  inline: {
    $default: (entry: Entry) => {
      return `<span><b>${entry.title}</b> - </span>`;
    },
  },
  // to display embedded items inserted via link
  link: (entry: Entry, metadata: any) => {
    return `<a href="${metadata.attributes.href}">${metadata.text}</a>`;
  },
  // to display assets
  display: (asset: Asset, metadata: AssetMetadata) => {
    console.log(
      metadata.attributes.height,
      metadata.attributes.width,
      asset,
      metadata
    );

    // float-right
    return ReactDOMServer.renderToString(
      <Image
        image={asset}
        render={{
          className: classNames(
            metadata.attributes.inline
              ? metadata.attributes.position === AssetAlignment.left
                ? "float-left"
                : metadata.attributes.position === AssetAlignment.right
                ? "float-right"
                : "float-none"
              : "",
            !metadata.attributes.inline
              ? metadata.attributes.position === AssetAlignment.left
                ? "image-left"
                : metadata.attributes.position === AssetAlignment.right
                ? "image-right"
                : metadata.attributes.position === AssetAlignment.center
                ? "image-center"
                : "block"
              : ""
          ),
          ariaHidden: "true",
          desktop:{
          height: metadata.attributes.height,
          width: metadata.attributes.width,
        }}}
      />
    );

    // <Image image={image} render={{ className: '', ariaHidden: 'true', width: count? 1152/count: 1152 ,  crop: '4:4,smart' }} />

    // return `<img test="sdf" src=${asset.url} alt=${metadata.alt} />`
  },

  // img: (asset: any) => {
  //   return (
  //     `<span>image</span><img src=${asset?.attrs?.url} test="sdf" alt=${asset?.alt} />`
  //   )
  // },
  // 'cta_widget': (asset: any, metadata: any) => {
  //   return (
  //     `<div style="text-align: ${asset?.attrs?.style?.['text-align']}" >
  //       <a
  //         href=${asset?.attrs?.url}
  //         class='btn ${asset?.attrs?.ctaPreference}'
  //         target=${asset?.attrs?.target}
  //         style="${asset?.attrs?.textArea}"
  //       >
  //         ${asset?.children && asset?.children[0] && asset?.children[0]?.text}
  //       </a>
  //     </div>`
  //   )
  // },
  // 'code': (asset: any, metadata: any) => {
  //   return (
  //     `<div className="code">${asset.children[0].text}</div>`
  //   )
  // },
  // 'embed_video': (asset: any, metadata: any) => {
  //   return (
  //     ` <p dangerouslySetInnerHTML={{ __html: ${asset?.attrs?.url} }} />`

  //   )
  // },

  //   'variable-plugin': (asset: any) => {
  //     const variableData = groupData?.data?.length && groupData?.data[0]?.filter((value: any) => asset.attrs.var.uid === value.uid)
  //     if(variableData===false){
  //       return(
  //         ''
  //       )
  //     }else{
  //       return (
  //         variableData&&variableData[0]?.group?.length > 0 && variableData[0]?.group?.map(
  //           (variable: { multi_line: string; url: string }) =>
  //             variable?.url
  //               ? `<div>
  //               <a href=${variable?.url}>${variable?.multi_line}</a>
  //             </div>`
  //               :`<div>
  //               <p>${variable?.multi_line}</p>
  //             </div>`
  //         )
  //       )
  //     }

  //   },
  //   'market-pre': (asset: any, metadata: any) => {
  //     return (
  //       `<div  class='marketPre' data-country=${asset?.attrs?.checked.length>0&&asset?.attrs?.checked}> `
  //     )
  //   },
  //   'market-post': (asset: any, metadata: any) => {
  //     return (
  //       '</div>'
  //     )
  //   },
  //   'block': {
  //     'product_component': (entry: any, metadata: any) => {
  //       const totalproduct = []
  //       if (entry?.product?.data?.length) {
  //         imageCardMediumObj.title = entry?.title
  //         for (let i = 0; i < entry.product.data.length; i++) {
  //           const product = {
  //             'title': entry.product?.data[i]?.name,
  //             'abstract': entry.product?.data[i]?.price,
  //             'url': `p${entry.product?.data[i]?.custom_url.url}`,
  //             'image': {
  //               'url': entry.product?.data[i]?.primary_image.url_standard,
  //               'title': entry.product?.data[i]?.value
  //             }
  //           }
  //           totalproduct.push(product)
  //           imageCardMediumObj.image_section = totalproduct
  //         }
  //       }

  //       return (
  //         ReactDOMServer.renderToString(
  //           <ImageCardMedium
  //             contents={{
  //               title: imageCardMediumObj?.title,
  //               paragraph: '',
  //               image_section: imageCardMediumObj?.image_section?.length
  //                 ? imageCardMediumObj.image_section
  //                 : undefined
  //             }}
  //           />)
  //       )
  //     }
  //   },
};
