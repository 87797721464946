import { classNames } from "utils";
import { Cta } from "./Cta";

import { CtaArrangement } from "types/enums";
import type { CtaCollection as CtaCollectionProps } from "types/components";

const CtaCollection: React.FC<CtaCollectionProps> = (
  props: CtaCollectionProps
) => {
  const { ctas, arrangement } = props;
  return (
    <div
      className={classNames(
        arrangement === CtaArrangement.horizontal
          ? "flex sm:flex-row flex-col"
          : "flex flex-col",
        "py-2 sm:gap-2 text-center"
      )}
    >
      {ctas &&
        ctas.map((c, idx) => {
          return <Cta key={idx} {...c} />;
        })}
    </div>
  );
};
export { CtaCollection };
