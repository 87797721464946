export enum AssetAlignment {
    none = 'none',
    left = 'left',
    right = 'right',
    center = 'center'
  }

export interface AssetMetadata {
  attributes: {
    "asset-alt": string,
    "asset-caption": string,
    "asset-link": string,
    "asset-name": string,
    "asset-type": string,
    "asset-uid": string,
    "class-name": string,
    "content-type-uid": string,
    "display-type": string,
    height: number,
    inline: boolean,
    link: string,
    "max-height": number,
    "max-width": number,
    position: AssetAlignment,
    "redactor-attributes": {
      alt: string,
      position: AssetAlignment,
      caption: string,
      anchorLink: string,
      inline: boolean,
    },
    style: {
      "text-align": AssetAlignment,
      "max-width": string,
      float: AssetAlignment,
      width: string,
      "max-height": string,
    },
    target: string,
    type: string,
    width: number,
    contentTypeUid: string,
    itemType: string,
    itemUid: string,
    styleType: string,
    text: string,
  },
}
