import { LoaderFunctionArgs, Params, redirect } from "react-router-dom";

import { getServerWebConfiguration } from 'services';
import { getLocaleCookie } from "utils";

export const unlocalizedRootLoader = (args: LoaderFunctionArgs) => {

    // redirects to locale site if there is a locale cookie already defined
    const cookieLocale = getLocaleCookie();
    if (cookieLocale) {
        return redirect(`/${cookieLocale}`)
    } else {
        const { params } = args
        return getRoot(params)
    }
}

export const getRoot = async (params: Params) => {
    return getServerWebConfiguration();

}
