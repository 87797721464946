import { useParams } from "react-router-dom";

import {defaultLocale, defaultLocaleName} from 'config';

import { Localization } from "types/generic";

function useLocalization(): Localization {
  const { locale } = useParams();  

  let currentlocaleName: string ;
  let currentLocale ;

  if(locale){
    currentLocale = new Intl.Locale(locale);
    currentlocaleName = locale;
  }else{
    currentLocale = defaultLocale;
    currentlocaleName = defaultLocaleName;
  }

  const routing:Localization ={
    defaultLocale,
    defaultLocaleName,
    currentLocale,
    currentlocaleName
  }

  return routing;
}
export { useLocalization }
