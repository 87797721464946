import {
  createBrowserRouter,
  RouterProvider,
  useLoaderData,
  useLocation,
  Link, Outlet,
  useParams,
  Navigate
} from "react-router-dom";
import React, { Fragment, useState } from 'react'

import { MegaMenu, TopNavigation } from "components/Header";
import { FooterNavigation, FooterEnd } from "components/Footer";


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface SingleColProps {
  Header?: React.FC
  Footer?: React.FC
  Hero?: React.FC
  children: React.ReactNode
}

const SingleCol: React.FC<SingleColProps> = ({ Header, Footer, Hero, children }: React.PropsWithChildren<SingleColProps>) => {
  
  return (
    <div className="bg-white">
      {/* Navigation */}
      <header className="relative z-10">
        <TopNavigation />
        <MegaMenu />
      </header>

      {/* Hero section */}
      <div className="relative bg-white">
       
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <img
            src="https://images.contentstack.io/v3/assets/bltbac5ac29681c0467/blt238f05159211571b/643e7e4f793eef21c330b711/david-dvoracek-12P0_rzSNpM-unsplash.jpg"
            alt=""
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div aria-hidden="true" className="absolute inset-0 bg-gray-100 opacity-50" />


        <div className="relative mx-auto flex max-w-3xl flex-col items-center py-32 px-6 text-center sm:py-64 lg:px-0">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 lg:text-6xl">New arrivals are here</h1>
          <p className="mt-4 text-xl text-gray-900">
            The new arrivals have, well, newly arrived. Check out the latest options from our summer small-batch release
            while they're still in stock.
          </p>
          <a
            href="#"
            className="mt-8 inline-block rounded-md border border-transparent bg-white py-3 px-8 text-base font-medium text-gray-900 hover:bg-gray-100"
          >
            Shop New Arrivals
          </a>
        </div>
      </div>

      <main className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        {children}
      </main>

      <footer aria-labelledby="footer-heading" className="bg-white">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <FooterNavigation />
        <FooterEnd />
      </footer>
    </div>
  )
}

export { SingleCol }