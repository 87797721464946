import * as React from "react";
// import * as ReactDOM from "react-dom";
import {
    createBrowserRouter,
    RouterProvider,
    useRouteError,
    isRouteErrorResponse
} from "react-router-dom";


export default function RootBoundary() {

    const error = useRouteError();
  
    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        return <div>This page doesn't exist!</div>;
      }
  
      if (error.status === 401) {
        return <div>You aren't authorized to see this</div>;
      }
  
      if (error.status === 503) {
        return <div>Looks like our API is down</div>;
      }
  
      if (error.status === 418) {
        return <div>🫖</div>;
      }
    }
  
    return <div>Something went wrong</div>;
  }