export class ContentstackMiddlewarePlugin {
  onRequest(stack, request) {
    if (process.env.REACT_APP_MIDDLEWARE_HOST) {

      const middlewareHost = new URL(process.env.REACT_APP_MIDDLEWARE_HOST)

      const url = new URL(request['url']);
      const urlSplit = url.pathname.split('/')
      if (urlSplit.length === 6) {

        // change host
        url.host = middlewareHost.host
        url.protocol = middlewareHost.protocol
        url.pathname = middlewareHost.pathname
        url.port = middlewareHost.port

        //add type to the query parameters
        const type = urlSplit[3];
        url.searchParams.set('type', type);

        // remove headers authentication headers
        delete request['option']['headers']['access_token'];
        delete request['option']['headers']['api_key'];
        request['option']['headers']['mode'] = 'no-cors'

        request.url = url.toString();
      } else {
        return request;
      }
    }
    return request
  }
  
  onResponse(stack, request, response, data) {
    // const _data = data['entries'] || data['assets'] || data['entry'] || data['asset'];
    return data
  }
}